const IconSportsFilled = [
  'american-football-filled',
  'archery-filled',
  'aussie-rules-filled',
  'badminton-filled',
  'bandy-filled',
  'baseball-filled',
  'basketball-filled',
  'basketball3x3-filled',
  'bat-cricket-filled',
  'bat-filled',
  'beach-handball-filled',
  'beach-soccer-filled',
  'beach-volley-filled',
  'biathlon-filled',
  'bobsleigh-filled',
  'bowls-filled',
  'boxing-filled',
  'call-of-duty-filled',
  'chess-filled',
  'counter-strike-filled',
  'cricket-filled',
  'curling-filled',
  'cybersport-filled',
  'cycling-filled',
  'darts-filled',
  'dota2-filled',
  'field-hockey-filled',
  'figure-skating-filled',
  'floorball-filled',
  'formula1-filled',
  'freestyling-skiing-filled',
  'futsal-filled',
  'golf-filled',
  'handball-filled',
  'ice-hockey-filled',
  'kabaddi-filled',
  'league-of-legends-filled',
  'mma-filled',
  'nordic-combined-filled',
  'overwatch-filled',
  'pesapallo-filled',
  'pool-filled',
  'rugby-filled',
  'short-track-filled',
  'skeleton-filled',
  'ski-jump-filled',
  'snooker-filled',
  'snowboard-filled',
  'soccer-filled',
  'speed-skating-filled',
  'sports-filled',
  'squash-filled',
  'table-tennis-filled',
  'tennis-filled',
  'volleyball-filled',
  'waterpolo-filled',
  'wrestling-filled',
] as const

const IconSports = [
  'alpine-skiing',
  'american-football',
  'arena-of-valor',
  'aussie-rules',
  'autosport',
  'badminton',
  'bandy',
  'baseball',
  'basketball',
  'basketball3x3',
  'bat',
  'beach-soccer',
  'beach-volley',
  'biathlon',
  'bowls',
  'boxing',
  'call-of-duty',
  'chess',
  'counter-strike',
  'cricket',
  'cross-country',
  'curling',
  'cyber-basketball',
  'cyber-hockey',
  'cyber-soccer',
  'cyber-tennis',
  'cyber-volleyball',
  'cybersport',
  'cycling',
  'darts',
  'dota2',
  'field-hockey',
  'fifa-cyber',
  'floorball',
  'formula1',
  'futsal',
  'golf',
  'handball',
  'ice-hockey',
  'indy-car',
  'king-of-glory',
  'league-of-legends',
  'mma',
  'olympic-games',
  'overwatch',
  'pubg',
  'rainbow-six',
  'rocket-league',
  'rugby',
  'sailing',
  'ski-jump',
  'snooker',
  'soccer',
  'all-sports',
  'sports',
  'squash',
  'star-craft',
  'stock-car',
  'table-tennis',
  'tennis',
  'valorant',
  'volleyball',
  'waterpolo',
  'winter-sports',
  'world-of-warcraft',
] as const

type SportIcon = (typeof IconSports)[number] | (typeof IconSportsFilled)[number]

const sportIcons: Record<
  'sport' | 'category' | 'tournament',
  Record<number, SportIcon>
> = {
  sport: {
    '0': 'sports',
    '6': 'aussie-rules',
    '7': 'badminton',
    '8': 'bandy',
    '9': 'baseball',
    '10': 'basketball',
    '11': 'basketball3x3',
    '16': 'beach-volley',
    '20': 'bowls',
    '21': 'boxing',
    '24': 'chess',
    '25': 'cricket',
    '26': 'cross-country',
    '27': 'curling',
    '28': 'cycling',
    '33': 'darts',
    '40': 'arena-of-valor',
    '44': 'call-of-duty',
    '64': 'overwatch',
    '66': 'pubg',
    '69': 'rainbow-six',
    '83': 'field-hockey',
    '87': 'floorball',
    '88': 'american-football',
    '92': 'futsal',
    '96': 'golf',
    '99': 'handball',
    '102': 'ice-hockey',
    '105': 'indy-car',
    '111': 'mma',
    '126': 'bat',
    '135': 'rugby',
    '144': 'ski-jump',
    '145': 'snooker',
    '149': 'soccer',
    '157': 'squash',
    '165': 'table-tennis',
    '167': 'tennis',
    '172': 'volleyball',
    '173': 'waterpolo',
    '10001': 'sports',
    '100248': 'cybersport',
    '89': 'formula1',
    '17': 'biathlon',
    '46': 'counter-strike',
    '100225': 'winter-sports',
    '100175': 'winter-sports',
    '123': 'olympic-games',
    '81': 'world-of-warcraft',
    '59': 'league-of-legends',
    '194': 'valorant',
    '2': 'alpine-skiing',
    '14': 'beach-soccer',
    '100137': 'sailing',
    '118': 'autosport',
    '100118': 'autosport',
    '158': 'stock-car',
    '192': 'stock-car',
    '47': 'dota2',
    '73': 'star-craft',
    '70': 'rocket-league',
    '58': 'king-of-glory',
  },
  category: {
    '1335': 'fifa-cyber',
    '2125': 'basketball',
    '2131': 'ice-hockey',
    '3588046': 'cyber-volleyball',
    '102580': 'dota2',
    '102357': 'counter-strike',
    '102584': 'league-of-legends',
    '102600': 'call-of-duty',
    '102587': 'king-of-glory',
    '102596': 'valorant',
    '102590': 'rainbow-six',
  },
  tournament: {},
}

interface GetSportIconOptions {
  sportId: number
  categoryId?: number
  tournamentId?: number
}

/**
 * Позволяет получить имя иконки спорта.
 * Обычно нужен только sportId, но для некоторых категорий (типа киберфутбола) есть исключения.
 * Поэтому для корректного определения лучше кидать и categoryId и tournamentId
 * @param sportId
 * @param categoryId
 * @param tournamentId
 */
export function getSportIcon({
  sportId,
  categoryId,
  tournamentId,
}: GetSportIconOptions): SportIcon {
  const defaultIcon: SportIcon = 'all-sports'

  return (
    (tournamentId && sportIcons.tournament[tournamentId]) ||
    (categoryId && sportIcons.category[categoryId]) ||
    sportIcons.sport[sportId] ||
    defaultIcon
  )
}
